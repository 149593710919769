import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';

// Lazy load other pages for better performance
const About = React.lazy(() => import('./pages/About'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const OneOOne = React.lazy(() => import('./pages/101'));
const Note = React.lazy(() => import('./pages/Note'));

const LoadingSpinner: React.FC = () => (
  <div className="container mt-5 text-center">
    <div className="spinner-border text-secondary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const PageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="wrap">
    {children}
  </div>
);

const App: React.FC = () => {

  return (
    <Router>
      <PageLayout>
        <Header />
        <main className="main-content">
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/101" element={<OneOOne />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/note" element={<Note />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </PageLayout>
    </Router>
  );
};

export default App;
