import React, { useEffect, useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { createRoot } from 'react-dom/client';

interface QRCodeButtonProps {
  address: string;
  currency: string;
}

const QRCodeButton: React.FC<QRCodeButtonProps> = ({ address, currency }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!buttonRef.current) return;

    // Create QR code container
    const qrContainer = document.createElement('div');
    qrContainer.className = 'qr-container text-center';
    
    // Create wrapper for QR code
    const qrWrapper = document.createElement('div');
    qrWrapper.className = 'qr-wrapper';
    qrContainer.appendChild(qrWrapper);

    // Create React root and render QR code
    const root = createRoot(qrWrapper);
    root.render(
      <div style={{ background: 'white', padding: '10px', display: 'inline-block' }}>
        <QRCodeSVG
          value={address}
          size={200}
          level="H"
          includeMargin={true}
        />
      </div>
    );

    // Add address text
    const addressText = document.createElement('div');
    addressText.className = 'mt-2 small text-break';
    addressText.textContent = address;
    qrContainer.appendChild(addressText);

    // Initialize popover
    new bootstrap.Popover(buttonRef.current, {
      html: true,
      content: qrContainer,
      placement: 'top',
      trigger: 'focus'
    });

    // Cleanup
    return () => {
      const popover = bootstrap.Popover.getInstance(buttonRef.current!);
      if (popover) {
        popover.dispose();
      }
    };
  }, [address, currency]);

  return (
    <button
      ref={buttonRef}
      type="button"
      className="btn btn-link p-0 text-decoration-none qr"
      data-bs-toggle="popover"
      aria-label={`Show QR code for ${currency} address`}
      tabIndex={0}
    >
      <span className="fas fa-qrcode"></span>
    </button>
  );
};

export default QRCodeButton;