import React from 'react';
import { Link } from 'react-router-dom';
import QRCodeButton from './QRCodeButton';

interface DonationAddress {
  currency: string;
  address: string;
}

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const donationAddresses: DonationAddress[] = [
    {
      currency: 'Bitcoin',
      address: 'bc1qthe6fz9aeknmkwv3fcw83uxjc275qfejhr7jx2'
    },
    {
      currency: 'Ethereum',
      address: '0xa2238F43261a4d29BE41e9F96F849a9293d80BE4'
    },
    {
      currency: 'Monero',
      address: '42SKtcBbfp4MgaMpptgf7tDEcA7jQr791Zystjbrf7JHHwrYgFMYtRH13NFbH5nbNCV6V4H56AmYRYau5kG8dhe7LYtuYtQ'
    }
  ];

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <span className="small">
              Donate:{' '}
              {donationAddresses.map((addr, index) => (
                <React.Fragment key={addr.currency}>
                  <QRCodeButton 
                    address={addr.address} 
                    currency={addr.currency} 
                  /> {addr.currency}
                  {index < donationAddresses.length - 1 && ' | '}
                </React.Fragment>
              ))}
              <br />
              OpenPGP:{' '}
              <a
                href="https://keys.openpgp.org/search?q=dcd2264c8edb5ec5ef9854a8c802c57a6d3ebe0c"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                dcd2264c8edb5ec5ef9854a8c802c57a6d3ebe0c
              </a>
              {' | '}
              Copyright &copy; {currentYear} proofof.cash
              {' | '}
              <Link to="/privacy" className="text-white">Privacy</Link>
            </span>
          </div>
        </div>
      </div>

      <style>
        {`
          .btn-link.qr {
            vertical-align: baseline;
            font-size: inherit;
          }
          
          .btn-link.qr:hover {
            opacity: 0.8;
          }

          .site-footer a:hover {
            opacity: 0.8;
            text-decoration: none;
          }
        `}
      </style>
    </footer>
  );
};

export default Footer;