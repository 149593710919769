import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface SocialLink {
  url: string;
  icon: string;
  label: string;
}

const Header: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();

  const socialLinks: SocialLink[] = [
    {
      url: "https://mastodon.social/@proofofcash",
      icon: "fa-brands fa-mastodon",
      label: "Mastodon"
    },
    {
      url: "https://matrix.to/#/!gaRrGHxiWbCCgDOgyI:chat.proofof.cash",
      icon: "fa fa-comment-dots",
      label: "Matrix"
    },
    {
      url: "https://x.com/proofof_cash",
      icon: "fa-brands fa-x-twitter",
      label: "X/Twitter"
    },
    {
      url: "https://discord.gg/f2uUAZY",
      icon: "fa-brands fa-discord",
      label: "Discord"
    }
  ];

  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  return (
    <header role="banner">
      <div className="top-bar">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-3 col-md-12 social">
              {socialLinks.map((link, index) => (
                <a 
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={link.label}
                >
                  <span className={link.icon}></span>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container logo-wrap">
        <div className="row pt-5">
          <div className="col-12 text-center">
            <h1 className="site-logo">
              <Link to="/">
                #proofof<span style={{ color: '#6d0000' }}>cash</span>
              </Link>
            </h1>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-md navbar-light">
        <div className="container d-block">
          <div className="position-relative">
            <button 
              className="navbar-toggler" 
              type="button" 
              onClick={() => setIsNavOpen(!isNavOpen)}
              aria-controls="navbarMenu" 
              aria-expanded={isNavOpen} 
              aria-label="Toggle navigation"
            >
              <div className="burger-lines-container">
                <span className={`burger-lines ${isNavOpen ? 'active' : ''}`}></span>
              </div>
            </button>

            <div 
              className={`navbar-collapse ${isNavOpen ? 'show' : 'collapse'}`} 
              id="navbarMenu"
            >
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <Link 
                    className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} 
                    to="/"
                    onClick={() => setIsNavOpen(false)}
                  >
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <Link 
                    className={`nav-link ${location.pathname === '/101' ? 'active' : ''}`} 
                    to="/101"
                    onClick={() => setIsNavOpen(false)}
                  >
                    101
                  </Link>
                </li>
                <li className="nav-item">
                  <Link 
                    className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`} 
                    to="/about"
                    onClick={() => setIsNavOpen(false)}
                  >
                    ABOUT
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <style>
        {`
          .navbar {
            background: white;
            padding: 0;
            border-bottom: 1px solid #e6e6e6;
          }

          .navbar .container {
            background: white;
            padding-bottom: 1em;
            padding-top: 1em;
          }

          .navbar-toggler {
            border: none;
            padding: 0;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 15px;
            top: -55px;
            z-index: 1030;
            background: transparent !important;
          }

          .navbar-toggler:focus {
            box-shadow: none;
            outline: none;
          }

          .burger-lines-container {
            width: 30px;
            height: 20px;
            position: relative;
            display: flex;
            align-items: center;
          }

          .burger-lines {
            width: 30px;
            height: 2px;
            background: #000;
            position: relative;
            transition: all 0.3s ease;
          }

          .burger-lines::before,
          .burger-lines::after {
            content: '';
            position: absolute;
            width: 30px;
            height: 2px;
            background: #000;
            transition: all 0.3s ease;
          }

          .burger-lines::before {
            transform: translateY(-8px);
          }

          .burger-lines::after {
            transform: translateY(8px);
          }

          .burger-lines.active {
            background: transparent;
          }

          .burger-lines.active::before {
            transform: rotate(45deg);
          }

          .burger-lines.active::after {
            transform: rotate(-45deg);
          }

          @media (max-width: 767.98px) {
            .navbar-collapse {
              text-align: center;
            }

          }

          @media (min-width: 768px) {
            .navbar-toggler {
              display: none;
            }

            .navbar-collapse {
              display: block !important;
            }

            .navbar-nav {
              display: flex;
              justify-content: center;
            }

            .nav-link {
              padding: 0 1rem !important;
            }
          }

          .nav-link {
            text-transform: uppercase;
            letter-spacing: .05em;
            color: #000;
          }

          .nav-link.active {
            color: #6d0000 !important;
          }

          @media (max-width: 991.98px) {
            .social, .text-end {
              text-align: center !important;
              margin-bottom: 0.5rem;
            }
            
            .top-bar .container .row {
              flex-direction: column;
            }
          }
        `}
      </style>
    </header>
  );
};

export default Header;