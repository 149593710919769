import React, { useEffect, useState } from 'react';
import '../styles/style.css';

interface BlockchainStats {
  blockhalving: number;
  blocks_left: number;
  days_left: number;
  hours_left: number;
  minutes_left: number;
}

const Home: React.FC = () => {
  const [blockchainStats, setBlockchainStats] = useState<BlockchainStats | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(Date.now());

  // Fetch blockchain statistics (auto-refresh every 5 minutes)
  useEffect(() => {
    document.title = "proofof.cash - Home";
    const controller = new AbortController();

    const fetchBlockchainStats = async () => {
      try {
        const response = await fetch('/api/blockchain', { signal: controller.signal });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: BlockchainStats = await response.json();
        setBlockchainStats(data);
        setError(null);
      } catch (err: any) {
        if (err.name !== 'AbortError') {
          console.error('Error fetching blockchain stats:', err);
          setError('Failed to load blockchain statistics. Please try again later.');
        }
      }
    };

    fetchBlockchainStats();
    const statsIntervalId = setInterval(fetchBlockchainStats, 5 * 60 * 1000);

    return () => {
      controller.abort();
      clearInterval(statsIntervalId);
    };
  }, []);

  // Update current time every second for a live countdown timer
  useEffect(() => {
    const timerId = setInterval(() => setCurrentTime(Date.now()), 1000);
    return () => clearInterval(timerId);
  }, []);

  // Render a live countdown timer using blocks_left (assuming an average block time of 10 minutes)
  const renderCountdown = () => {
    if (!blockchainStats) return null;
    const totalSecondsRemaining = blockchainStats.blocks_left * 10 * 60;
    const days = Math.floor(totalSecondsRemaining / (3600 * 24));
    const hours = Math.floor((totalSecondsRemaining % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSecondsRemaining % 3600) / 60);
    return (
      <div className="d-flex justify-content-around mt-3">
        <div>
          <strong>{days.toLocaleString()}</strong>
          <div className="small">days</div>
        </div>
        <div>
          <strong>{hours.toLocaleString()}</strong>
          <div className="small">hours</div>
        </div>
        <div>
          <strong>{minutes.toLocaleString()}</strong>
          <div className="small">minutes</div>
        </div>
      </div>
    );
  };

  // Updated halving history: using a concise format for modern display
  const halvingHistory = [
    { id: 1, year: 2012, initialReward: 50, newReward: 25 },
    { id: 2, year: 2016, initialReward: 25, newReward: 12.5 },
    { id: 3, year: 2020, initialReward: 12.5, newReward: 6.25 },
    { id: 4, year: 2024, initialReward: 6.25, newReward: 3.125 },
  ];

  return (
    <main className="site-section pt-5">
      <div className="container">
        <header className="row mb-4">
          <div className="col-12">
            <h1>Bitcoin</h1>
          </div>
        </header>

        <div className="row">
          <div className="col-12">
            {/* Display error message if any */}
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {/* Loading state for blockchain stats */}
            {!error && !blockchainStats && (
              <div className="text-center p-4">
                <span>Loading blockchain statistics...</span>
              </div>
            )}

            {blockchainStats && (
              <>
                {/* Modern Halving Stats Card */}
                <section className="halving-stats mb-4">
                  <div className="card shadow-sm border-0">
                    <div className="card-body">
                      <h5 className="card-title">Upcoming Bitcoin Halving</h5>
                      <p className="card-text">
                        Target Block: <strong>{blockchainStats.blockhalving.toLocaleString()}</strong>
                        <br />
                        Remaining Blocks: <strong>{blockchainStats.blocks_left.toLocaleString()}</strong>
                      </p>
                      {renderCountdown()}
                    </div>
                  </div>
                </section>

                {/* Educational Content */}
                <section className="bitcoin-info mb-4">
                  <h4>What is it all about?</h4>
                  <p>
                    Halving is an anti-inflation mechanism implemented in the Bitcoin network.
                    It reduces the supply of new coins by 50% approximately every 4 years.
                    Current mining rewards are 3.125 bitcoins.
                  </p>
                  <p>
                    <a 
                      href="/pdf/bitcoin.pdf" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="mt-2 d-inline-block"
                    >
                      Bitcoin: A Peer-to-Peer Electronic Cash System
                    </a>
                  </p>
                </section>

                {/* Modern Halving History Timeline */}
                <section className="halving-history mb-4">
                  <h4>Historical Halving Events</h4>
                  <div className="row">
                    {halvingHistory.map(event => (
                      <div key={event.id} className="col-md-3 col-sm-6 mb-3">
                        <div className="card shadow-sm h-100 border-0">
                          <div className="card-body text-center">
                            <h5 className="card-title">{event.year}</h5>
                            <p className="card-text">
                              {event.initialReward} BTC &rarr; {event.newReward} BTC
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
