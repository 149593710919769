import React, { useEffect, useState } from 'react';
import '../styles/style.css';

interface BlockchainStats {
  blockhalving: number;
  blocks_left: number;
  days_left: number;
  hours_left: number;
  minutes_left: number;
}

function Home() {
  const [blockchainStats, setBlockchainStats] = useState<BlockchainStats | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    document.title = "proofof.cash - Home";
    const fetchBlockchainStats = async () => {
      try {
        // Use relative URL which will be proxied
        const response = await fetch('/api/blockchain');
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setBlockchainStats(data);
        setError(null);
      } catch (error) {
        console.error('Error fetching blockchain stats:', error);
        setError('Failed to load blockchain statistics. Please try again later.');
      }
    };

    fetchBlockchainStats();
    // Refresh data every 5 minutes
    const interval = setInterval(fetchBlockchainStats, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="site-section pt-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="mb-4">
              Bitcoin
            </h1>
            
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {!error && !blockchainStats && (
              <div className="text-center p-4">
                <div>Loading blockchain statistics...</div>
              </div>
            )}

            {blockchainStats && (
              <div>
                <div className="halving-stats mb-4">
                  <p>
                    <div className="stat-row">
                      The next halving will happen at block:{' '}
                      <b>{blockchainStats.blockhalving.toLocaleString()}</b>
                    </div>
                    <div className="stat-row">
                      This means that{' '}
                      <b>{blockchainStats.blocks_left.toLocaleString()} blocks</b>{' '}
                      are left.
                    </div>
                    <div className="stat-row">
                      It will approximately happen in...
                    </div>
                    <div className="countdown">
                      <div>
                        <b>{Math.floor(blockchainStats.days_left).toLocaleString()} days</b>
                      </div>
                      <div>
                        <b>{Math.floor(blockchainStats.hours_left).toLocaleString()} hours</b>
                      </div>
                      <div>
                        <b>{Math.floor(blockchainStats.minutes_left).toLocaleString()} minutes</b>!
                      </div>
                    </div>
                  </p>
                </div>

                <div className="bitcoin-info">
                  <h4 className="mb-3">What is it all about?</h4>
                  <p>
                    Halving is an anti-inflation mechanism implemented in the Bitcoin network.
                    It reduces the supply of new coins by 50% approximately every 4 years.
                    Current mining rewards are 3.125 bitcoins.
                    <br />
                    <a 
                      href="/pdf/bitcoin.pdf" 
                      target="_blank" 
                      rel="noreferrer"
                      className="mt-2 d-inline-block"
                    >
                      Bitcoin: A Peer-to-Peer Electronic Cash System
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;